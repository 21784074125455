

const DataDayOne =[
    {
        "event": "Conferencista magistral",
        "schedule": "2:30-3:30 p.m.",
        "activities": [
          {
            "name": "",
            "topics": [
              {
                "topic": ""
              },
              {
                "topic": ""
              },
              {
                "topic": ""
              },
              {
                "topic": ""
              }
            ]
          }
        ]
      
    }
];
  

export default DataDayOne;