

import React from 'react'

import WALMARTMexicoyCentroamerica from "../../../assets/sponsors/oro/WALMARTMexicoyCentroamerica.jpg";
import CerveceriaHondure from "../../../assets/sponsors/oro/CerveceriaHondure.png";
import progresaConEducacion from "../../../assets/sponsors/oro/progresaConEducacion.png";


function Oro() {
    return (
        <div className="py-8 flex flex-wrap md:flex-nowrap" data-aos="fade-up" data-aos-duration="3000">
            <div className="md:w-60 md:mb-0 mb-6 flex-shrink-0 flex flex-col mr-8">
                <div>
                    <button class="text-white bg-yellow-fundahrse  border-0 py-2 px-6 focus:outline-none font-bold rounded-3xl text-lg">
                        Oro
                    </button>
                </div>
            </div>

            <div className="md:flex-grow">
                <div class="flex flex-wrap -m-4">

                    <div class="lg:w-1/3 md:w-1/2 p-4 w-full">
                        <a class="relative h-48 rounded overflow-hidden flex items-center justify-center ">
                            <img alt="ecommerce" class="object-cover object-center  bloc" src={WALMARTMexicoyCentroamerica} />
                        </a>
                    </div>
                 
                    <div class="lg:w-1/3 md:w-1/2 p-4 w-full">
                        <a class="relative h-48 rounded overflow-hidden flex items-center justify-center ">
                            <img alt="ecommerce" class="object-cover object-center  bloc" src={CerveceriaHondure} />
                        </a>
                    </div>
                   
                    <div class="lg:w-1/3 md:w-1/2 p-4 w-full">
                        <a class="relative h-48 rounded overflow-hidden flex items-center justify-center ">
                            <img alt="ecommerce" class="object-cover object-center  bloc" src={progresaConEducacion} />
                        </a>
                    </div>


                </div>
            </div>

        </div>
    )
}

export default Oro;