

const speakers = [
    {
        "subtitle": "Cooperación Alemana al Desarrollo, GIZ",
        "name": "Tessa Noemí",
        "last": "Sandoval Pina",
        "photo": "tessaSandoval.jpg",
        "socialMedia": {
            "twitter": "",
            "facebook": "Tessa Sandoval",
            "instagram": "",
            "webSite": "www.giz.de@biodiversidadynegocios"
        },
        "contact":{
            "gmail":"Tessa.sandoval@giz.de",
            "phone":"+504 9455-4613"
        }
    },
    {
        "subtitle": "BID Invest (Brazo Privado del BID)",
        "name": "Marta Viegas",
        "last": "",
        "photo": "MartaViegas.jpg",
        "socialMedia": {
            "twitter": "",
            "facebook": "Marta’s Profile",
            "instagram": "",
            "webSite": "linkedin.com/in/martaviegas"
        },
        "contact":{
            "gmail":"mcoellopaz@iadb.org, martavi@iadb.org",
            "phone":"9923-3372"
        }
    },
    {
        "subtitle": "BID",
        "name": "Ginés Suárez",
        "last": "",
        "photo": "GinesSuarez.PNG",
        "socialMedia": {
            "twitter": "",
            "facebook": "",
            "instagram": "",
            "webSite": "https://sv.linkedin.com/in/gines-suarez"
        },
        "contact":{
            "gmail":"giness@iadb.org",
            "phone":"+503-79180947"
        }
    },
    {
        "subtitle": "UNDRR",
        "name": "Yezid Fernando",
        "last": "Niño Barrero",
        "photo": "YezidFernando.PNG",
        "socialMedia": {
            "twitter": "",
            "facebook": "Tessa Sandoval",
            "instagram": "",
            "webSite": "https://www.linkedin.com/in/yfnino/"
        },
        "contact":{
            "gmail":"Yezid.ninobarrero@un.org.co",
            "phone":"+57 3002105020"
        }
    },
    {
        "subtitle": "Coordinador de El Salvador en G.S.A.S.R",
        "name": "Eliezer Daniel",
        "last": "Velásquez Rodríguez",
        "photo": "speakers.PNG",
        "socialMedia": {
            "twitter": "",
            "facebook": "Tessa Sandoval",
            "instagram": "",
            "webSite": "www.giz.de@biodiversidadynegocios"
        },
        "contact":{
            "gmail":"Tessa.sandoval@giz.de",
            "phone":"+504 9455-4613"
        }
    },
    {
        "subtitle": "Coordinador de El Salvador en G.S.A.S.R",
        "name": "Eliezer Daniel",
        "last": "Velásquez Rodríguez",
        "photo": "speakers.PNG",
        "socialMedia": {
            "twitter": "",
            "facebook": "Tessa Sandoval",
            "instagram": "",
            "webSite": "www.giz.de@biodiversidadynegocios"
        },
        "contact":{
            "gmail":"Tessa.sandoval@giz.de",
            "phone":"+504 9455-4613"
        }
    },
    {
        "subtitle": "Coordinador de El Salvador en G.S.A.S.R",
        "name": "Eliezer Daniel",
        "last": "Velásquez Rodríguez",
        "photo": "speakers.PNG",
        "socialMedia": {
            "twitter": "",
            "facebook": "Tessa Sandoval",
            "instagram": "",
            "webSite": "www.giz.de@biodiversidadynegocios"
        },
        "contact":{
            "gmail":"Tessa.sandoval@giz.de",
            "phone":"+504 9455-4613"
        }
    }
];

export default speakers;