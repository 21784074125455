
import React from 'react'


import gobernanza from "../../assets/categories/gobernanza.png";
import ambiental from "../../assets/categories/ambiental.png";
import social from "../../assets/categories/social.png";
import economia from "../../assets/categories/economia.png";

import About from "../about/about";

function Categories() {
    return (
        // <section className="bg-sky-blue-categories-fundahrse">
         <section className="bg-fondoAbout bg-about-section" id='CONECTA' > 
            
          
            <div className="container lg:px-32 pb-16 px-8  mx-auto z-50">

                <div className="flex flex-wrap text-center">

                    <div className="px-12 md:w-1/2 sm:w-1/2 w-full pt-8">
                        <div className="rounded-lg flex flex-col items-center p-12 bg-oragen-fundahrse">
                            <img src={gobernanza} className="w-32 h-32" />
                            <h2 className="title-font font-medium text-2xl text-white text-left px-8 ">Gobernanza</h2>
                        </div>
                    </div>

                    <div className="px-12 md:w-1/2 sm:w-1/2 w-full pt-8">
                        <div className="px-4 rounded-lg flex flex-col items-center p-12 bg-yellow-fundahrse">
                            <img src={economia} className="w-32 h-32" />
                            <h2 className="title-font font-medium text-2xl text-white text-left px-8">Economía</h2>
                        </div>
                    </div>

                    <div className="px-12 md:w-1/2 sm:w-1/2 w-full pt-8">
                        <div className="px-4 rounded-lg flex flex-col items-center p-12 bg-blue-fundahrse">
                            <img src={social} className="w-32 h-32 "/>
                            <h2 className="title-font font-medium text-2xl text-white text-left px-8">Social</h2>
                        </div>
                    </div>

                    <div className="px-12 md:w-1/2 sm:w-1/2 w-full pt-8">
                        <div className="px-4 rounded-lg flex flex-col items-center p-12 bg-green-fundahrse ">
                            <img src={ambiental} className="w-32 h-32" />
                            <h2 className="title-font font-medium text-2xl text-white text-left px-4 mb-1">Ambiental</h2>
                        </div>
                    </div>

                </div>

            </div>

            <div className='pb-24'>
            <About></About>
            </div>
            
        </section>
    );
}

export default Categories