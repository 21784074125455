
import React from 'react'

import carosa from "../../../assets/sponsors/bronce/carosa.png";
import OPC from "../../../assets/sponsors/bronce/OPC.png";
import SupermercadosLaColonia from "../../../assets/sponsors/bronce/SupermercadosLaColonia.png";

function Bronce() {
    return (
        <div className="py-8 flex flex-wrap md:flex-nowrap" data-aos="fade-up" data-aos-duration="3000">
            <div className="md:w-60 md:mb-0 mb-6 flex-shrink-0 flex flex-col mr-8">
                <div>
                    <button class="text-white bg-yellow-dark-fundahrse  border-0 py-2 px-6 focus:outline-none font-bold rounded-3xl text-lg">
                        BRONCE
                    </button>
                </div>
            </div>

            <div className="md:flex-grow">
                <div class="flex flex-wrap -m-4">

                    <div class="lg:w-1/5 md:w-2/2 p-4 w-full">
                        <a class="relative rounded h-48 overflow-hidden flex items-center justify-center">
                            <img alt="ecommerce" class="object-cover object-center bloc w-9/12" src={carosa} />
                        </a>
                    </div>
                   
                    <div class="lg:w-1/5 md:w-2/2 p-4 w-full">
                        <a class="relative rounded h-48 overflow-hidden flex items-center justify-center">
                            <img alt="ecommerce" class="object-cover object-center bloc w-9/12" src={OPC} />
                        </a>
                    </div>

                    <div class="lg:w-1/5 md:w-2/2 p-4 w-full">
                        <a class="relative rounded h-48 overflow-hidden flex items-center justify-center">
                            <img alt="ecommerce" class="object-cover object-center bloc w-9/12" src={SupermercadosLaColonia} />
                        </a>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Bronce