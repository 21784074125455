import React from 'react'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import DayOne from './days/dayOne';
import DayTwo from './days/dayTwo';
import DayThree from './days/dayThree';
import DayFour from './days/dayFour';
import DayFive from './days/dayFive';
import { useState } from 'react';


function Schedule() {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const [showTabs, setShowModal] = useState(1);
    const [activeTabs, setActiveTabs] = useState(1);

    const HandlerButtonsTabs = (ID) => {
        setShowModal(Number(ID));
        setActiveTabs(Number(ID));
    };

    return (
        <section className="text-gray-600 body-font overflow-hidden bg-green-fundahrse" id='AGENDA'>

            <div className="w-full mt-16 lg:mb-0 " >
                <h1 className='text-white text-5xl font-extrabold text-center' data-aos="fade-left">AGENDA</h1>
            </div>

            <div className="container px-5 py-24 mx-auto"  data-aos="fade-up" data-aos-duration="3000">

                <div className='container buttons-tabs mb-16'>

                    <div class="flex flex-wrap -m-4">
                        <div class="lg:px-64 md:w-1/1 w-full">
                            <div class="flex flex-wrap -m-4 justify-center">
                                <div class="-mx-1 lg:w-1/5 md:w-1/2">
                                    <div class="h-full flex flex-col items-center text-center">
                                        <button class={`${(activeTabs == 1) ? 'bg-yellow-600' : ''} text-white border-2 border-yellow-500 py-2 px-6 focus:outline-none hover:bg-yellow-600 roundedd text-2xl font-semibold`} onClick={() => HandlerButtonsTabs(1)}>
                                            Día 1
                                        </button>
                                    </div>
                                </div>

                                <div class="-mx-1 lg:w-1/5 md:w-1/2">
                                    <div class="h-full flex flex-col items-center text-center">
                                        <button class={`${(activeTabs == 2) ? 'bg-yellow-600' : ''} text-white border-2 border-yellow-500 py-2 px-6 focus:outline-none hover:bg-yellow-600 roundedd text-2xl font-semibold`} onClick={() => HandlerButtonsTabs(2)}>
                                            Día 2
                                        </button>
                                    </div>
                                </div>

                                <div class="-mx-1 lg:w-1/5 md:w-1/2">
                                    <div class="h-full flex flex-col items-center text-center">
                                        <button class={`${(activeTabs == 3) ? 'bg-yellow-600' : ''} text-white border-2 border-yellow-500 py-2 px-6 focus:outline-none hover:bg-yellow-600 roundedd text-2xl font-semibold`} onClick={() => HandlerButtonsTabs(3)}>
                                            Día 3
                                        </button>
                                    </div>
                                </div>

                                <div class="-mx-1 lg:w-1/5 md:w-1/2">
                                    <div class="h-full flex flex-col items-center text-center">
                                        <button class={`${(activeTabs == 4) ? 'bg-yellow-600' : ''} text-white border-2 border-yellow-500 py-2 px-6 focus:outline-none hover:bg-yellow-600 roundedd text-2xl font-semibold`} onClick={() => HandlerButtonsTabs(4)}>
                                            Día 4
                                        </button>
                                    </div>
                                </div>
                               
                                <div class="-mx-1 lg:w-1/5 md:w-1/2">
                                    <div class="h-full flex flex-col items-center text-center">
                                        <button class={`${(activeTabs == 5) ? 'bg-yellow-600' : ''} text-white border-2 border-yellow-500 py-2 px-6 focus:outline-none hover:bg-yellow-600 roundedd text-2xl font-semibold`} onClick={() => HandlerButtonsTabs(5)}>
                                            Día 5
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>


                {(showTabs === 1) && <div className='w-full flex justify-center animate__animated animate__fadeIn' > <DayOne /> </div>  }
                {(showTabs === 2) && <div className='w-full flex justify-center animate__animated animate__fadeIn' > <DayTwo /> </div>  }
                {(showTabs === 3) && <div className='w-full flex justify-center animate__animated animate__fadeIn' > <DayThree /> </div>  }
                {(showTabs === 4) && <div className='w-full flex justify-center animate__animated animate__fadeIn' > <DayFour /> </div>  }
                {(showTabs === 5) && <div className='w-full flex justify-center animate__animated animate__fadeIn' > <DayFive /> </div>  }



                {/* <Carousel
                    responsive={responsive}
                    showDots={true}
                    arrows={false}
                    // autoPlay={true}
                    // autoPlaySpeed={5000}
                    dotListClass="custom-dot-list-style"
                >

                    <div className='sm:px-4 py-8'>
                        <div className="flex flex-wrap text-center">
                            <DayOne />
                            <DayTwo />
                        </div>
                    </div>

                    <div className='sm:px-4 py-8'>
                        <div className="flex flex-wrap text-center">
                            <DayThree />
                            <DayFour />
                        </div>
                    </div>

                    <div className='sm:px-4 py-8'>
                        <div className="flex flex-wrap text-center">
                            <DayFive />
                        </div>
                    </div>
                    
                </Carousel> */}

                {/* <div className='w-full text-center pt-16'>
                    <button class="text-white bg-blue-fundahrse border-0 py-2 px-6 focus:outline-none font-medium rounded-3xl text-2xl">
                        Regístrate
                    </button>
                </div> */}

            </div>

        </section>
    )
}

export default Schedule