import React from 'react'


function SustainabilityWeek() {
    return (
        <section class="text-gray-600 body-font overflow-hidden pl-8" id='NOSOTROS'>
            <div class="container-fluid py-36 mx-auto">
                <div class="-my-8 divide-y-2 divide-gray-100">
                    <div class="py-8 flex flex-wrap md:flex-nowrap">
                        <div class="md:mb-0 mb-6 flex-shrink-0 z-50 flex justify-center items-center" data-aos="fade-up" data-aos-duration="3000">
                            <div class='lg:z-10  lg:-mr-[38px] lg:px-0 px-4 '>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/eBeVw82opo0?si=URyG8XjDY-N584YU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div class="md:flex-grow bg-yellow-dark-fundahrse py-16" data-aos="fade-up" data-aos-duration="3000">
                            <div className='lg:px-24 px-4 flex flex-col justify-center'>
                                <h2 class="text-5xl font-bold text-white title-font mb-8 text-center">
                                Semana de la Sostenibilidad 2023: ¡Conecta con un futuro sostenible! 
                                </h2>
                                <p class="leading-relaxed text-black font-semibold text-base text-justify">
                                En 2023 en la decimosexta edición de la Semana de la Sostenibilidad descubrimos cómo la innovación puede ayudar a la empresa a ser más sostenible y a contribuir a un mundo mejor. Asimismo generamos acciones que contribuyeron a garantizar un futuro sostenible accesible para todos y todas a través de la conexión del sector empresarial y otros actores con los retos y oportunidades en el entorno de la Sostenibilidad. 
                                </p>

                                <div className='flex justify-end py-4'>
                                    <button class="text-white bg-blue-fundahrse border-0 py-1 px-7 focus:outline-none font-bold rounded-3xl text-xl">
                                        Leer más
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SustainabilityWeek