
import React from 'react'

import data from "../../data/speakers/speakers";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


function Speakers() {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };





  return (

    <section>
      <div className="container px-16 py-24 mx-auto" id='SPONSORYALIADOS'>

        <div className="flex flex-wrap w-full mb-20">
          <div className="w-full mb-6 lg:mb-0">
            <h1 className='text-blue-fundahrse lg:text-5xl text-3xl font-extrabold text-center' data-aos="fade-up" data-aos-duration="3000">CONFERENCISTAS</h1>
          </div>
        </div>

        <div data-aos="fade-up" data-aos-duration="3000">
          <Carousel
            responsive={responsive}
            showDots={true}
            arrows={false}
            autoPlay={true}
            infinite={true}
            autoPlaySpeed={2000}
            dotListClass="custom-dot-list-style"
            >

            {
                data.map((speaker) =>{
                  
                  return(
                    <div className='sm:px-4 py-8'>
                    <div className="bg-gray-100">

                    <img 
                          className="rounded w-96 h-96 bg-no-repeat bg-center bg-cover object-cover" 
                          src={require(`../../assets/speakers/${speaker.photo}`)} // Importa la imagen dinámicamente
                          alt={speaker.photo}
                      />
                      <div className='p-4 bg-blue-fundahrse'>
                        <h3 className="tracking-widest text-yellow-fundahrse text-xs font-medium title-font">
                         

                          {
                            (speaker.subtitle.length <= 5 ) ?(
                              <>
                                {speaker.subtitle} 
                                <br/>
                                <br/>
                              </>
                            ):(
                              speaker.subtitle
                            )
                          }
                          
                          </h3>
                        <h2 className="text-lg text-white font-medium title-font">{speaker.name}</h2>
                        <h2 className="text-lg text-white font-medium title-font">
                          {
                            (speaker.last !="") ? (
                              speaker.last
                            ):(
                              <br/>
                            )
                          }
                          </h2>
                      </div>

                    </div>
                  </div>
                  )

                })
            }



          </Carousel>
        </div>

        <div className="flex flex-wrap">




        </div>

      </div>
    </section>
  );
}

export default Speakers;