
import React from 'react'

import ficihsa from "../../../assets/sponsors/plata/Ficohsa.jpg";
import cargill from "../../../assets/sponsors/plata/cargill.jpg";
import CenosaLargo from "../../../assets/sponsors/plata/CenosaLargo.png";
import Diunsa from "../../../assets/sponsors/plata/Diunsa.png";
import SeaboardHonduras from "../../../assets/sponsors/plata/SeaboardHonduras.jpg";
import plasticosVanguardia from "../../../assets/sponsors/plata/plasticosVanguardia.png";

function Plata(){


    return (
        <div className="py-8 flex flex-wrap md:flex-nowrap" data-aos="fade-up" data-aos-duration="3000">
            <div className="md:w-60 md:mb-0 mb-6 flex-shrink-0 flex flex-col mr-8">
                <div>
                    <button class="text-white bg-gray-400 border-0 py-2 px-6 focus:outline-none font-bold rounded-3xl text-lg">
                        PLATA
                    </button>
                </div>
            </div>

            <div className="md:flex-grow">
                <div class="flex flex-wrap -m-4">

                    <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
                        <a class="relative h-48 rounded overflow-hidden flex items-center justify-center">
                            <img alt="ecommerce" class="object-cover  object-center  bloc w-11/12" src={ficihsa} />
                        </a>
                    </div>
                    
                    <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
                        <a class="relative h-48 rounded overflow-hidden flex items-center justify-center">
                            <img alt="ecommerce" class="object-cover object-center  bloc w-11/12" src={cargill} />
                        </a>
                    </div>
                   
                    <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
                        <a class="relative h-48 rounded overflow-hidden flex items-center justify-center">
                            <img alt="ecommerce" class="object-cover object-center  bloc w-11/12" src={CenosaLargo} />
                        </a>
                    </div>
                   
                    <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
                        <a class="relative h-48 rounded overflow-hidden flex items-center justify-center">
                            <img alt="ecommerce" class="object-cover object-center  bloc w-11/12" src={Diunsa} />
                        </a>
                    </div>
                   
                    <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
                        <a class="relative h-48 rounded overflow-hidden flex items-center justify-center">
                            <img alt="ecommerce" class="object-cover object-center  bloc w-11/12" src={plasticosVanguardia} />
                        </a>
                    </div>
                    
                    <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
                        <a class="relative h-48 rounded overflow-hidden flex items-center justify-center">
                            <img alt="ecommerce" class="object-cover object-center  bloc w-11/12" src={SeaboardHonduras} />
                        </a>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Plata;