import React from 'react'

import data from "../../../data/schedule/dataDayThree";

function DayThree() {
    return (
        <div className="sm:w-full mb-10 px-4 ">
            <div className="bg-blue-fundahrse">

                <button class="
                                      text-white bg-yellow-fundahrse border-0 
                                        py-2 px-16 focus:outline-none text-xl font-bold 
                                        rounded-b-lg">
                    DÍA 3
                </button>

                <div class="container mx-auto pt-8">

                    {
                        data.map((event) => {
                            return (
                                <div class="flex items-center py-8 lg:w-1/1 px-4 mx-auto sm:flex-row flex-col">

                                    {/* <div class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-4 
                                            inline-flex items-center justify-center 
                                            rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0"></div> */}

                                    <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                                        <button class="
                                        text-white bg-green-fundahrse 
                                        border-0 py-1 px-4 focus:outline-none 
                                        rounded-3xl ">
                                            | {event.schedule} |
                                        </button>

                                       <div className='px-2'>
                                       <h2 class="text-white text-lg title-font font-medium mb-1 mt-1">{event.event}</h2>
                                        {
                                            event.activities.map((activitie) => {
                                                return (

                                                    <>
                                                        <h2 class="text-yellow-fundahrse text-base title-font font-medium">{activitie.name}</h2>
                                                        {
                                                            (!activitie.topics[0].topic == "") ? (
                                                                <ul class="list-disc px-4 my-2">
                                                                    {
                                                                        activitie.topics.map((topic) => {
                                                                            return (
                                                                                <li className='text-white'>
                                                                                    {topic.topic}
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                    </>
                                                );
                                            })
                                        }
                                       </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>

            </div>
        </div>
    )
}

export default DayThree