import React from 'react'

function Footer() {
    return (
        <footer class="text-gray-600 body-font">
            <div class="bg-blue-fundahrse">
                <div class="container mx-auto py-4 px-5 flex justify-center">
                    <p class="text-white text-xl text-center">
                        Copyright © 2024 FUNDAHRSE
                        Fundación Hondureña de Responsabilidad Social <br/>  Hecho en El Salvador por
                        <a href="https://www.conexion.sv/" rel="noopener noreferrer" class="ml-1 text-yellow-fundahrse" target="_blank">
                        CONEXION
                        </a> 
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer