const DataDayFour = [
    {
        "event": "Visitas Técnicas",
        "schedule": "8:30-11:00 a.m.",
        "activities": [
            {
                "name": "",
                "topics": [
                    {
                        "topic": "Xxxxx"
                    },
                    {
                        "topic": "Xxxxx"
                    },
                    {
                        "topic": "Xxxxx"
                    }
                ]
            }
        ]

    }
];


export default DataDayFour;