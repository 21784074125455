
import React from 'react'

import about from "../../assets/about/aboutImg.png";

import mainTitle from "../../assets/about/mainTitle.png";

function About() {
    return (
        <div className="container mx-auto flex px-8 py-20 md:flex-row flex-col items-center">

            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 md:mb-0">
                <img className="object-cover object-center rounded" alt="hero" src={about} data-aos="zoom-in-down" data-aos-duration="3000" />
            </div>

            <div className="lg:flex-grow md:w-1/2 lg:pl-4 flex flex-col md:items-start items-center">


                <div className=''>
              
                    <img src={mainTitle} className='lg:w-8/12 lg:py-1 w-full py-4'  data-aos="fade-left" data-aos-duration="4000" />
                
                    <p className="mb-4 mt-2 leading-relaxed text-justify text-sm px-6 " data-aos="fade-up" data-aos-duration="3000">Uno de los principios clave de los modelos de negocio hoy en día es la creación de valor
                        para todas las partes interesadas involucradas, incluidos los colaboradores, los clientes, las
                        comunidades y el  ambiente. Al priorizar el bienestar de estas partes interesadas, las
                        empresas pueden construir negocios resilientes y sostenibles que prosperen en el largo
                        plazo.
                    </p>

                    <p className="mb-4 leading-relaxed text-justify text-sm px-6 " data-aos="fade-up" data-aos-duration="3000">Los modelos de negocio sostenibles y resilientes alientan a las empresas a pensar más allá
                        de los enfoques tradicionales y fomentar la innovación. Al adoptar la sostenibilidad y la
                        responsabilidad social, los empresarios pueden identificar nuevas oportunidades de
                        mercado, desarrollar productos y servicios innovadores, identificar riesgos, desarrollar
                        estrategias de abordaje de criterios ambientales, sociales y de gobernanza para luego
                        adaptarse a los requerimientos cambiantes del mercado.
                    </p>

                </div>

            </div>

        </div>
    )
}

export default About;