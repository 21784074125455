
const DataDayThree = [
  {
    "event": "Inscripciones",
    "schedule": "8:00 a.m.",
    "activities": [
      {
        "name": "",
        "topics": [
          {
            "topic": ""
          },
          {
            "topic": ""
          },
          {
            "topic": ""
          },
          {
            "topic": ""
          }
        ]
      }
    ]

  },
  {
    "event": "Actos Protocolarios e inauguración de Expo -Sostenibilidad",
    "schedule": "8:40-9:20 a.m.",
    "activities": [
      {
        "name": "",
        "topics": [
          {
            "topic": ""
          },
          {
            "topic": ""
          },
          {
            "topic": ""
          },
          {
            "topic": ""
          }
        ]
      }
    ]

  },
  {
    "event": "Actos de Inauguración Exposostenibilidad",
    "schedule": "10:00 a.m.",
    "activities": [
      {
        "name": "Brunch y networkimg",
        "topics": [
          {
            "topic": ""
          },
          {
            "topic": ""
          },
          {
            "topic": ""
          },
          {
            "topic": ""
          }
        ]
      }
    ]
  },
  {
    "event": "ExpoSostenibilidad",
    "schedule": "10:00-5:00 p.m.",
    "activities": [
      {
        "name": "",
        "topics": [
          {
            "topic": ""
          },
          {
            "topic": ""
          },
          {
            "topic": ""
          },
          {
            "topic": ""
          }
        ]
      }
    ]

  },
  {
    "event": "Conectando por la Sostenibilidad (Encuentros y alianzas entre actores)",
    "schedule": "9:20-10:00 a.m.",
    "activities": [
      {
        "name": "Brunch y networkimg",
        "topics": [
          {
            "topic": "Educación de calidad transversal con temas con salud y nutrición"
          },
          {
            "topic": "Generando Prosperidad con empleabilidad y emprendimiento transversal con igualdad genero"
          },
          {
            "topic": "Acción por el planeta (acción climática y economía circular)"
          },
          {
            "topic": "Ética, anticorrupción y reputación"
          }
        ]
      }
    ]

  }
];


export default DataDayThree;