
const DataDayFour = [
    {
        "event": "Inscripciones",
        "schedule": "8:00-8:40 a.m.",
        "activities": [
            {
                "name": "",
                "topics": [
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    }
                ]
            }
        ]

    },
    {
        "event": "Conferencia Magistral Biodiversidad: “Capital Natural y servicios ecosistémicos”",
        "schedule": "8:30 -9:10 a.m.",
        "activities": [
            {
                "name": "",
                "topics": [
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    }
                ]
            }
        ]

    },
    {
        "event": "Conferencia: “Atendiendo los riesgos climáticos con enfoque sistémico para la continuidad de negocios”",
        "schedule": "9:10-10:00 a.m.",
        "activities": [
            {
                "name": "",
                "topics": [
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    }
                ]
            }
        ]

    },
    {
        "event": "Coffee Break",
        "schedule": "10:00-10:40 a.m.",
        "activities": [
            {
                "name": "",
                "topics": [
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    }
                ]
            }
        ]

    },
    {
        "event": "Panel empresarial: “Liderando la Acción climática para la continuidad de negocios”",
        "schedule": "10:40-11:40 a.m.",
        "activities": [
            {
                "name": "",
                "topics": [
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    }
                ]
            }
        ]

    },
    {
        "event": "Inversiones Climáticas sostenibles",
        "schedule": "11:40-12:20 a.m.",
        "activities": [
            {
                "name": "",
                "topics": [
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    }
                ]
            }
        ]

    },
    {
        "event": "xxxxxxx",
        "schedule": "12:20-13:00 a.m.",
        "activities": [
            {
                "name": "",
                "topics": [
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    }
                ]
            }
        ]

    },
    {
        "event": "Almuerzo",
        "schedule": "13:00-14:00 a.m.",
        "activities": [
            {
                "name": "",
                "topics": [
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    }
                ]
            }
        ]

    }, 
    {
        "event": "Educación de Calidad para el Desarrollo",
        "schedule": "14:00-15:00 a.m.",
        "activities": [
            {
                "name": "",
                "topics": [
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    }
                ]
            }
        ]

    },
    {
        "event": "Empleo y emprendimiento justo e inclusivo",
        "schedule": "15-00-16:00 a.m.",
        "activities": [
            {
                "name": "",
                "topics": [
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    }
                ]
            }
        ]

    },
    {
        "event": "Presentación Artística (área de stand)",
        "schedule": "16:00-16:30 a.m.",
        "activities": [
            {
                "name": "",
                "topics": [
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    }
                ]
            }
        ]
    },
    {
        "event": "Coctel de negocios (área de stand)",
        "schedule": "16:30-18:00 a.m.",
        "activities": [
            {
                "name": "",
                "topics": [
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    },
                    {
                        "topic": ""
                    }
                ]
            }
        ]
    }
];


export default DataDayFour;