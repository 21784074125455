import React from 'react'

import data from "../../../data/schedule/dataDayOne.js";

function DayOne() {
    return(
        <div className="sm:w-full mb-10 px-4 ">
            <div className="bg-blue-fundahrse">

                <button class="
                                        text-white bg-yellow-fundahrse border-0 
                                        py-2 px-16 focus:outline-none text-xl font-bold 
                                        rounded-b-lg">
                    DÍA 1
                </button>

                <div class="container mx-auto pt-8 ">

                  <div className='px-2'>
                  {
                        data.map((event)=>{

                            return(
                                <div class="flex items-center py-8 lg:w-1/1 px-4 mx-auto sm:flex-row flex-col">

                                    {/* <div class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-4 
                                        inline-flex items-center justify-center 
                                        rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0"></div> */}

                                    <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">

                                        <button class="
                                                            text-white bg-green-fundahrse 
                                                            border-0 py-1 px-4 focus:outline-none 
                                                            rounded-3xl ">
                                            | {event.schedule} |
                                        </button>

                                        <h2 class="text-white text-lg title-font font-medium mb-1 mt-1">{event.event}</h2>

                                        <h2 class="text-yellow-fundahrse text-base title-font font-medium"></h2>

                                        <p class="leading-relaxed text-sm text-white"></p>

                                        <button class="text-black bg-white  border-0 py-2 my-2 px-6 focus:outline-none font-bold rounded-3xl text-lg">
                                            Regístrate
                                        </button>


                                    </div>
                                </div>
                            )
                        })
                    }
                  </div>


                </div>
            </div>
        </div>

    )
}

export default DayOne;