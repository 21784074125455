import React from 'react'
import Estrategico from './estrategico/estrategico';
import Oro from './oro/oro';
import Plata from './plata/plata';
import Bronce from './bronce/bronce';

function Sponsors() {
    return (
        <section className="text-gray-600 body-font overflow-hidden">
            <h1 className='text-blue-fundahrse lg:text-5xl text-3xl font-extrabold text-center'>PATROCINADORES</h1>
            <div className="container px-5 py-24 mx-auto" >
                <div className="-my-8">
                    <Estrategico  />
                    <Oro  />
                    <Plata  />
                    <Bronce  />
                </div>
            </div>
        </section>
    )
}

export default Sponsors;