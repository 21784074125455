
const DataDayOne =[
    {
        "event": "Gestion de la Gobernanza aspecto clave en la gestión de riesgos Reputacionales",
        "schedule": "9:00-10:30 a.m.",
        "activities": [
          {
            "name": "",
            "topics": [
              {
                "topic": ""
              },
              {
                "topic": ""
              },
              {
                "topic": ""
              },
              {
                "topic": ""
              }
            ]
          }
        ]
      
    }
];
  

export default DataDayOne;