
import React from 'react'

import banner from "../../assets/header/banner.png";
import emblemaLetras from "../../assets/header/emblemaLetras.png";


export default function Header() {
    return (
        <section className="text-gray-600 body-font">
            <div className="container-fluid flex flex-wrap">
                <div className="w-full mx-auto">
                    <div className="flex flex-wrap justify-center w-full h-[900px] bg-gray-100 pt-32 lg:pb-52 pb-32 px-10 relative" >
                        
                        <img 
                            alt="gallery" 
                            className="w-full bg-cover bg-no-repeat object-cover h-full object-center block absolute inset-0" 
                            src={banner} 
                        />

                        <div className="text-center relative z-10 flex justify-center lg:pt-32  w-ful -mt-32 w-full h-full ">
                            <img src={emblemaLetras} className='lg:w-2/3 w-full' data-aos="fade-up" data-aos-duration="3000" />
                        </div>


                    </div>
                </div>
            </div>
        </section>
    )
};
