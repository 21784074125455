

import React from 'react'

function Estrategico() {
    return (
        <div className="py-8 flex flex-wrap md:flex-nowrap" data-aos="fade-up" data-aos-duration="3000">
            <div className="md:w-60 md:mb-0 mb-6 flex-shrink-0 flex flex-col mr-8">

                <div>
                    <button class="text-white bg-sky-blue-fundahrse border-0 py-2 px-6 focus:outline-none font-bold rounded-3xl text-lg">
                        ALIADO ESTRATÉGICO
                    </button>
                </div>

            </div>

            <div className="md:flex-grow">
                <div class="flex flex-wrap -m-4">
                    <div class="lg:w-2/4 md:w-1/1 p-4 w-full">
                        <a class="block relative h-48 rounded overflow-hidden">
                            {/* <img alt="ecommerce" class="object-cover object-center w-full h-full bloc" src="https://dummyimage.com/420x260" /> */}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Estrategico