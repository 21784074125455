import React, { useState } from 'react'

function Navbar(){

    const [open, setOpen] = useState(false);

    return (
        <div class="min-screen bg-blue-incubator-navbar  container-fluid w-full z-20 bg-blue-fundahrse">
        <div class="antialiased container ">
          <div class="w-full text-white ">
            <div class="flex flex-col max-w-screen-xl px-4 mx-auto lg:items-center lg:justify-between lg:flex-row lg:px-6 py-4">
              <div class="flex flex-row items-center justify-between p-4">
                
                <button class="rounded-lg lg:hidden focus:outline-none focus:shadow-outline" onClick={() => {
                  if (open) {
                    setOpen(false)
                  } else {
                    setOpen(true)
                  }
                }}>
                  <svg fill="currentColor" viewBox="0 0 20 20" class="w-6 h-6">
                    {
                      open ? (
                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                      ) : (
                        <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clip-rule="evenodd"></path>
                      )
                    }
                  </svg>
                </button>
              </div>

              <nav className={`flex-col flex-grow pb-4 lg:pb-0 lg:flex lg:justify-end lg:flex-row ${open ? 'flex' : 'hidden'}`}>
                <a class=" border-2 border-yellow-500 px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 lg:mt-0 lg:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#CONECTA">CONECTA</a>
                <a class="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 lg:mt-0 lg:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#NOSOTROS">SOBRE NOSOTROS</a>
                <a class="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 lg:mt-0 lg:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#AGENDA">AGENDA</a>
                <a class="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 lg:mt-0 lg:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#EXPOSITORES">EXPOSITORES</a>
                <a class="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 lg:mt-0 lg:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#SPONSORYALIADOS">SPONSOR Y ALIADOS</a>
               
              
  
                   
              </nav>
            </div>
          </div>
        </div>
      </div>
        // <header class="text-gray-600 body-font bg-blue-fundahrse">
        //     <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                
        //         <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center">
        //             <a class="mr-5 text-white font-bold cursor-pointer">CONECTA</a>
        //             <a class="mr-5 text-white font-bold	cursor-pointer">SOBRE NOSOTROS </a>
        //             <a class="mr-5 text-white font-bold	cursor-pointer">AGENDA</a>
        //             <a class="mr-5 text-white font-bold	cursor-pointer">EXPOSITORES</a>
        //             <a class="mr-5 text-white font-bold	cursor-pointer">SPONSOR Y ALIADOS</a>
        //         </nav>
        //         <button class="inline-flex items-center bg-sky-blue-fundahrse font-bold border-0 py-1 px-3 focus:outline-none text-white rounded text-base mt-4 md:mt-0">REGÍSTRATE
                    
        //         </button>
        //     </div>
        // </header>

    );
}

export default Navbar