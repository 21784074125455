
import './App.css';
import Categories from './components/categories/categories';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import Navbar from './components/navbar/navbar';
import Panelists from './components/panelists/panelists';
import Schedule from './components/schedule/schedule';
import Speakers from './components/speakers/speakers';
import Sponsors from './components/sponsors/sponsors';
import SustainabilityWeek from './components/sustainabilityWeek/sustainabilityWeek';

import 'animate.css';

import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init();

function App() {
  return (
    <>
      <Navbar />
      <Header />
      <Categories />
      <Schedule />
      <Speakers />
      <Panelists />
      <SustainabilityWeek />
      <Sponsors />
      <Footer />
    </>
  );
}

export default App;
