



const panelists = [
    {
        "subtitle": "Subdirectora Asuntos Corporativos",
        "name": "Mey Lang",
        "last": "Hung Murillo",
        "photo": "MeyHungWalmart.jpg",
        "socialMedia": {
            "twitter": "",
            "facebook": "Walmart Para Vivir Mejor",
            "instagram": "Walmart Para Vivir Mejor",
            "linkedin": "https://www.linkedin.com/in/meyhung/?locale=es_ES",
            "webSite": "https://www.walmartcentroamerica.com/"
        },
        "contact":{
            "gmail":"mey.hung@walmart.com",
            "phone":""
        }
    },
    {
        "subtitle": "Developer Code",
        "name": "Jorge Alberto",
        "last": "Velásquez Rodríguez",
        "photo": "speakers.PNG",
        "socialMedia": {
            "twitter": "",
            "facebook": "",
            "instagram": "",
            "linkedin": "",
            "webSite": ""
        },
        "contact":{
            "gmail":"",
            "phone":""
        }
    },
    {
        "subtitle": "Developer Code",
        "name": "Jorge Alberto",
        "last": "Velásquez Rodríguez",
        "photo": "speakers.PNG",
        "socialMedia": {
            "twitter": "",
            "facebook": "",
            "instagram": "",
            "linkedin": "",
            "webSite": ""
        },
        "contact":{
            "gmail":"",
            "phone":""
        }
    },
    {
        "subtitle": "Developer Code",
        "name": "Jorge Alberto",
        "last": "Velásquez Rodríguez",
        "photo": "speakers.PNG",
        "socialMedia": {
            "twitter": "",
            "facebook": "",
            "instagram": "",
            "linkedin": "",
            "webSite": ""
        },
        "contact":{
            "gmail":"",
            "phone":""
        }
    },
    {
        "subtitle": "Developer Code",
        "name": "Jorge Alberto",
        "last": "Velásquez Rodríguez",
        "photo": "speakers.PNG",
        "socialMedia": {
            "twitter": "",
            "facebook": "",
            "instagram": "",
            "linkedin": "",
            "webSite": ""
        },
        "contact":{
            "gmail":"",
            "phone":""
        }
    },
    {
        "subtitle": "Developer Code",
        "name": "Jorge Alberto",
        "last": "Velásquez Rodríguez",
        "photo": "speakers.PNG",
        "socialMedia": {
            "twitter": "",
            "facebook": "",
            "instagram": "",
            "linkedin": "",
            "webSite": ""
        },
        "contact":{
            "gmail":"",
            "phone":""
        }
    },
    {
        "subtitle": "Developer Code",
        "name": "Jorge Alberto",
        "last": "Velásquez Rodríguez",
        "photo": "speakers.PNG",
        "socialMedia": {
            "twitter": "",
            "facebook": "",
            "instagram": "",
            "linkedin": "",
            "webSite": ""
        },
        "contact":{
            "gmail":"",
            "phone":""
        }
    },
    {
        "subtitle": "Developer Code",
        "name": "Jorge Alberto",
        "last": "Velásquez Rodríguez",
        "photo": "speakers.PNG",
        "socialMedia": {
            "twitter": "",
            "facebook": "",
            "instagram": "",
            "linkedin": "",
            "webSite": ""
        },
        "contact":{
            "gmail":"",
            "phone":""
        }
    },
    {
        "subtitle": "Developer Code",
        "name": "Jorge Alberto",
        "last": "Velásquez Rodríguez",
        "photo": "speakers.PNG",
        "socialMedia": {
            "twitter": "",
            "facebook": "",
            "instagram": "",
            "linkedin": "",
            "webSite": ""
        },
        "contact":{
            "gmail":"",
            "phone":""
        }
    }
];

export default panelists;