
import React from 'react'
import data from "../../data/panelists/panelists";

function Panelists(){

    return (
        <section id='EXPOSITORES'>
            <div className="container px-16 py-16 mx-auto">
                <div className="flex flex-wrap w-full mb-20">
                    <div className="w-full mb-6 lg:mb-0">
                        <h1 className='text-blue-fundahrse lg:text-5xl text-3xl font-extrabold text-center' data-aos="fade-up" data-aos-duration="3000">PANELISTAS</h1>
                    </div>
                </div>
                <div className="flex flex-wrap">
                    
                    {
                        data.map((panelist) =>{
                            return(
                                <div className="xl:w-1/4 md:w-1/2 p-2" data-aos="fade-up" data-aos-duration="3000">
                                    <div className="bg-gray-100 ">

                                        <img 
                                            className="rounded w-full h-72 object-cover object-center" 
                                            src={require(`../../assets/panelists/${panelist.photo}`)} // Importa la imagen dinámicamente
                                            alt={panelist.photo}
                                            />

                                        <div className='p-4 bg-sky-blue-fundahrse'>
                                            <h3 className="tracking-widest text-yellow-fundahrse text-xs font-medium title-font">{panelist.subtitle}</h3>
                                            <h2 className="text-lg text-white font-medium title-font">{panelist.name}</h2>
                                            <h2 className="text-lg text-white font-medium title-font">{panelist.last}</h2>
                                        </div>

                                    </div>
                                </div>
                            )
                        })
                    }
                    
                </div>
            </div>
        </section>
    );
}


export default Panelists